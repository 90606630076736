import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import {
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator'
import { Contact } from './contact'

export class SaveLaboratoryContact {
  @IsString()
  @IsNotEmpty()
  firstName: string | undefined

  @IsString()
  @IsNotEmpty()
  lastName: string | undefined

  @IsNumber()
  @IsNotEmpty()
  languageId: number | undefined

  @IsArray()
  @ArrayNotEmpty()
  phoneNumbers: string[] | undefined

  @IsString()
  @IsOptional()
  mailAddress: string | undefined

  @IsNumber()
  @IsNotEmpty()
  priority: number | undefined

  @IsBoolean()
  @IsNotEmpty()
  active: boolean | undefined
}

export class SaveLaboratory {
  public static build(raw: any) {
    return plainToInstance(SaveLaboratory, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  @IsArray()
  @IsString({ each: true })
  @ArrayNotEmpty()
  types: string[] | undefined

  @IsArray()
  @IsNumber({}, { each: true })
  @ArrayNotEmpty()
  regionIds: number[] | undefined

  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => SaveLaboratoryContact)
  contacts: Contact[] | undefined
}
