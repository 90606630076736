import { Component } from '@angular/core'
import { NbToastrService } from '@nebular/theme'
import { MessageService } from './core/message/services/message.service'
import { environment } from '@masterdata/env/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'

// eslint-disable-next-line
declare var dT_
@Component({
  selector: 'md-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(
    private messageService: MessageService,
    private toastrService: NbToastrService,
    private http: HttpClient
  ) {
    this.messageService.message.subscribe((msg) => {
      if (msg?.message) {
        this.openToast(msg.message)
      }
    })
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(this.http, HttpHeaders)
    }
    this.loadExternalScript(environment.dynatraceScriptSrc)
  }

  openToast(message: string, action?: string, duration: number = 2000) {
    return this.toastrService.show(action, message, {
      duration,
      icon: undefined,
    })
  }

  private loadExternalScript(scriptUrl: string): void {
    if (!scriptUrl) {
      return
    }

    const scriptElement = document.createElement('script')
    scriptElement.src = scriptUrl
    scriptElement.type = 'text/javascript'
    document.body.appendChild(scriptElement)
  }
}
