import { Injectable } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Message } from '../models'

/**
 * Class responsible for generating local SfMessage objects
 */
@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public message: Observable<Message>
  private messageSubject: BehaviorSubject<Message> = new BehaviorSubject<Message>(null)

  constructor(private translate: TranslateService) {
    this.message = this.messageSubject.asObservable()
  }

  /**
   * Creates a Message object
   */
  public createMessage(msg: string): Message {
    return {
      message: msg,
      okAction: () => {},
      okLabel: this.translate.instant('OK'),
    }
  }

  public publishMessage(message: Message) {
    this.messageSubject.next(message)
  }
}
