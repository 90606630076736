<nb-layout windowMode>
  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
    <md-header></md-header>
    <ng-content class="menu-content" select="nb-menu"></ng-content>
    <nb-sidebar-footer>
      <md-footer></md-footer>
    </nb-sidebar-footer>
  </nb-sidebar>

  <nb-layout-column>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>
</nb-layout>
