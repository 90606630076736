export enum Precision {
  LOW = 'low',
  HIGH = 'high',
}

export class Location {
  id?: number
  latitude: string
  longitude: string
  precision?: Precision
  geohash?: string
  isFallbackGeolocation?: boolean

  constructor(location?: LocationDAO) {
    this.id = location?.id
    this.latitude = location?.latitude ?? ''
    this.longitude = location?.longitude ?? ''
    this.precision = location?.precision || Precision.LOW
    this.geohash = location?.geohash
  }
}

export class LocationDAO {
  id?: number
  latitude: string
  longitude: string
  precision?: Precision
  geohash?: string
  isFallbackGeolocation?: boolean

  constructor(location?: Location) {
    if (location?.id) {
      this.id = location.id
    }
    this.latitude = location?.latitude ?? ''
    this.longitude = location?.longitude ?? ''
    this.precision = location?.precision || Precision.LOW
    this.geohash = location?.geohash
  }
}
