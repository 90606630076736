<div [ngClass]="style" class="ao-dialog">
  <h2 *ngIf="title" mat-dialog-title>{{ title }}</h2>
  <mat-dialog-content>
    <p>{{ message }}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button mat-button class="actions-submit actions-submit-active" (click)="onConfirm()">
      {{ 'YES' | translate }}
    </button>
    <button mat-button class="actions-dismiss" (click)="onCancel()">
      {{ 'NO' | translate }}
    </button>
  </mat-dialog-actions>
</div>
