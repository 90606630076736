<div class="header-container">
  <div class="header-container-home-hub">
    <a [href]="hubUrl" target="_self">
      <img class="header-container-home-hub-logo" src="assets/icons/svg/home-header-icon.svg" />
    </a>
  </div>
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-outline"></nb-icon>
    </a>
    <div>
      <img src="assets/icons/svg/masterdata.svg" />
    </div>
  </div>
</div>
