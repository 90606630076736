import { CommonModule } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  standalone: true,
  imports: [MatDialogModule, TranslateModule, CommonModule, MatButtonModule],
})
export class ConfirmDialogComponent implements OnInit {
  public title?: string
  public message: string
  public style?: string

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string; style?: string; title?: string }
  ) {}

  ngOnInit(): void {
    this.title = this.data.title
    this.message = this.data.message
    this.style = this.data.style
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }
}
