import { Component, OnInit } from '@angular/core'
import { environment } from '@masterdata/env/environment'
import { NbSidebarService } from '@nebular/theme'

@Component({
  selector: 'md-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public expanded: boolean = false
  public hubUrl!: string

  constructor(private sidebarService: NbSidebarService) {}
  toggleSidebar(): boolean {
    this.expanded = !this.expanded
    this.sidebarService.toggle(true, 'menu-sidebar')

    return false
  }

  ngOnInit() {
    this.hubUrl = environment.hubUrl
  }
}
