import { Component } from '@angular/core'

@Component({
  selector: 'md-footer',
  styleUrls: ['./footer.component.scss'],
  template: `<span class="created-by">Bonduelle {{ this.currentYear }}</span>`,
})
export class FooterComponent {
  public currentYear: number = new Date().getFullYear()
}
