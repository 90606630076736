import {
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator'
import { Contact } from './contact'
import { Farm, SaveFarm } from './farm'
import { plainToInstance, Type } from 'class-transformer'
import { IsDateValidate } from '@agroone/helpers'
import { GrowerCooperativesDto } from './grower-cooperatives'
import { DateFormatV2 } from '@agroone/dates'

export class Grower {
  id: number | undefined
  growerName: string | undefined
  regions: { id: number; name: string }[] | undefined
  farms: Farm[] | undefined
  active: boolean | undefined
  contacts: Contact[] | undefined
  agreoCode?: string
  organization?: string
  technician?: string
  certificationOrganicNumber?: string
  certificationStartDate?: string
  certificationEndDate?: string
  metadata?: string
  farmNames?: string[]
  cooperatives?: GrowerCooperativesDto[]
  growerCsrId?: number

  constructor(grower?: Grower) {
    this.id = grower?.id
    this.growerName = grower?.growerName
    this.regions = grower?.regions
    this.farms = grower?.farms
    this.contacts = grower?.contacts
    this.agreoCode = grower?.agreoCode
    this.organization = grower?.organization
    this.technician = grower?.technician
    this.certificationOrganicNumber = grower?.certificationOrganicNumber
    this.certificationStartDate = grower?.certificationStartDate
    this.certificationEndDate = grower?.certificationEndDate
    this.metadata = grower?.metadata
    this.farmNames = grower?.farmNames
    this.cooperatives = grower?.cooperatives
    this.growerCsrId = grower?.growerCsrId
  }
}

export class SaveGrowerContact {
  @IsNumber()
  @IsOptional()
  id: number

  @IsString()
  @IsNotEmpty()
  firstName: string

  @IsString()
  @IsNotEmpty()
  lastName: string

  @IsNumber()
  @IsNotEmpty()
  languageId: number

  @IsArray()
  @ArrayNotEmpty()
  phoneNumbers: string[]

  @IsArray()
  @IsOptional()
  phoneNumbersSMS: string[]

  @IsString()
  @IsOptional()
  emailAddress: string

  @IsNumber()
  @IsNotEmpty()
  priority: number

  @IsBoolean()
  @IsNotEmpty()
  active: boolean

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    languageId: number,
    phoneNumbers: string[],
    phoneNumbersSMS: string[],
    emailAddress: string,
    priority: number,
    active: boolean
  ) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.languageId = languageId
    this.phoneNumbers = phoneNumbers
    this.phoneNumbersSMS = phoneNumbersSMS
    this.emailAddress = emailAddress
    this.priority = priority
    this.active = active
  }
}

export class SaveGrower {
  public static build(raw: any) {
    return plainToInstance(SaveGrower, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SaveFarm)
  @ArrayNotEmpty()
  farms: SaveFarm[] | undefined

  @IsString()
  @IsNotEmpty()
  growerName: string | undefined

  @IsArray()
  @IsString({ each: true })
  @IsNotEmpty()
  @ArrayNotEmpty()
  regions: string[] | undefined

  @IsBoolean()
  @IsNotEmpty()
  active: boolean | undefined

  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => SaveGrowerContact)
  contacts: SaveGrowerContact[] | undefined

  @IsString()
  @IsOptional()
  agreoCode?: string

  @IsString()
  @IsOptional()
  organization?: string

  @IsString()
  @IsOptional()
  technician?: string

  @IsString()
  @IsOptional()
  certificationOrganicNumber?: string

  @IsOptional()
  @IsDateValidate(DateFormatV2.SIMPLE)
  certificationStartDate?: string

  @IsOptional()
  @IsDateValidate(DateFormatV2.SIMPLE)
  certificationEndDate?: string

  @IsOptional()
  @IsArray()
  @IsNumber({}, { each: true })
  cooperativeIds?: number[]

  constructor(grower?: SaveGrower) {
    this.id = grower?.id
    this.cooperativeIds = grower?.cooperativeIds
    this.farms = grower?.farms
    this.growerName = grower?.growerName
    this.regions = grower?.regions
    this.active = grower?.active
    this.contacts = grower?.contacts
    this.agreoCode = grower?.agreoCode
    this.organization = grower?.organization
    this.technician = grower?.technician
    this.certificationOrganicNumber = grower?.certificationOrganicNumber
    this.certificationStartDate = grower?.certificationStartDate
    this.certificationEndDate = grower?.certificationEndDate
  }
}

export interface GrowerRequest {
  growerId: number
  growerName: string
  growerActive: number
  agreoCode: string
  organization: string
  technician: string
  certificationOrganicNumber: string
  certificationStartDate: string
  certificationEndDate: string
  regionId: number
  regionName: string
  farmName: string
  farmId: number
  contactId: number
  contactFirstName: string
  contactLastName: string
  contactLanguageId: number
  contactPhoneNumbers: string
  contactPhoneNumbersSMS: string
  contactEmailAddress: string
  contactPriority: number
  contactActive: boolean
  contactLanguage: string
  cooperativeName: string
  cooperativeId: number
  growerCsrId: number
}

export class GrowerDto {
  id?: number
  name: string | undefined
  active: number
  agreoCode?: string
  organization?: string
  technician?: string
  certificationOrganicNumber?: string
  certificationStartDate?: string
  certificationEndDate?: string
  createdDate?: string
  updatedDate?: string
  createdBy?: string
  updatedBy?: string
  cooperative?: GrowerCooperativesDto

  constructor(grower?: SaveGrower) {
    this.id = grower?.id
    this.name = grower?.growerName
    this.active = grower?.active ? 1 : 0
    this.agreoCode = grower?.agreoCode
    this.organization = grower?.organization
    this.technician = grower?.technician
    this.certificationOrganicNumber = grower?.certificationOrganicNumber
    this.certificationStartDate = grower?.certificationStartDate
    this.certificationEndDate = grower?.certificationEndDate
  }
}

export class GrowerContactDto {
  growerId!: number
  contactId!: number
}

export class GrowerRegionDto {
  growerId!: number
  regionId!: number
}

export class GrowerContact {
  firstName: string
  lastName: string
  mailAddress?: string
  phoneNumbers: string[]
  language: string
  priority: number
  active: boolean

  public static build(raw: any) {
    return plainToInstance(GrowerContact, raw)
  }
}
